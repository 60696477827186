import { MESSAGES } from '@fanatics/resn-api/white-elephant';
import { defineComponent } from 'vue';

import { useFanatics } from './FanaticsProvider';
import { useWindowMessage } from '~/composables/useWindowMessage';

const StateManager = defineComponent({
    setup() {
        const fanatics = useFanatics();

        useWindowMessage({
            onReceiveMessage: (e) => {
                if (!e.data.type) {
                    console.warn('StateManager: Window message object does not have a type:', e);
                    return;
                }

                console.log('StateManager: Message from parent:', e.data);

                const { data, type } = e.data;

                switch (type) {
                    case MESSAGES.Values.SETUP:
                        fanatics.setupGame(data);
                        break;
                    case 'ITEM_SELECTION':
                        fanatics.updateGameUserInput(data);
                        break;
                    default:
                        fanatics.updateGameData(data);
                }
            },
        });
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default StateManager;
